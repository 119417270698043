import React from "react";

import Card from "components/card";
import Button from "components/button";

import style from "./contentCard.module.scss";

const ContentCard = ({ img, tokenId, handleClick, status }) => {
  return (
    <div className={style.Wrapper}>
      <Card className={style.cardWrapper}>
        <div className={style.imgWrapper}>
          <img src={img} alt={"art pic"} width={150} height={150} />
        </div>
        <div className={style.contentWrapper}>
          <label className={style.title}>Baudet vs Schwab#{tokenId}</label>

          <div className={style.statusWrapper}>
            <span className={style.label}>Status:</span>
            <span className={style.value}>{status ? "Currently staked" : "Ready to stake"}</span>
          </div>
        </div>
        <div className={style.btnWrapper}>
          {
            status ? <Button title={"Unstake"} handleClick={handleClick} className={style.btnUnstake} /> : <Button title={"Stake"} handleClick={handleClick} className={style.btnCollect} />
          }
        </div>
      </Card>
    </div>
  );
};

export default ContentCard;
