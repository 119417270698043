import React, { useEffect, useState } from "react";
import Web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import WalletLink from "walletlink";
import WalletConnectProvider from "@walletconnect/web3-provider";

import ContentCard from "components/content-card";

import VAULTABI from "./VAULTABI.json";
import { NFTCONTRACT, STAKINGCONTRACT, moralisapi, nftpng } from "./config";

var web3 = null;
var account = null;
var vaultcontract = null;

const moralisapikey =
  "S4usFtOqBj40uqx3qIa6y9zA7dH0iRuv3US66vyBftkS5C9KKw7D5hPbdV1UsxHa";
const providerOptions = {
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "3cf2d8833a2143b795b7796087fff369",
    },
  },
  walletlink: {
    package: WalletLink,
    options: {
      appName: "Net2Dev NFT Minter",
      infuraId: "3cf2d8833a2143b795b7796087fff369",
      rpc: "",
      chainId: 4,
      appLogoUrl: null,
      darkMode: true,
    },
  },
};

const web3Modal = new Web3Modal({
  network: "rinkeby",
  theme: "dark",
  cacheProvider: true,
  providerOptions,
});

export default function NFT() {
  const [apicall, getNfts] = useState([]);
  const [nftstk, getStk] = useState([]);
  const [loadingState, setLoadingState] = useState("not-loaded");

  useEffect(() => {
    callApi();
  }, []);

  async function callApi() {
    var provider = await web3Modal.connect();
    web3 = new Web3(provider);
    await provider.send("eth_requestAccounts");
    var accounts = await web3.eth.getAccounts();
    account = accounts[0];
    vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
    let config = { "X-API-Key": moralisapikey, accept: "application/json" };
    const nfts = await axios
      .get(
        moralisapi + `/nft/${NFTCONTRACT}/owners?chain=polygon&format=decimal`,
        { headers: config }
      )
      .then((output) => {
        const { result } = output.data;
        return result;
      });
    const apicall = await Promise.all(
      nfts.map(async (i) => {
        let item = {
          tokenId: i.token_id,
          holder: i.owner_of,
          wallet: account,
        };
        return item;
      })
    );
    const stakednfts = await vaultcontract.methods
      .tokensOfOwner(account)
      .call()
      .then((id) => {
        return id;
      });
    const nftstk = await Promise.all(
      stakednfts.map(async (i) => {
        let stkid = {
          tokenId: i,
        };
        return stkid;
      })
    );
    getNfts(apicall);
    getStk(nftstk);
    console.log(apicall);
    setLoadingState("loaded");
  }
  if (loadingState === "loaded" && !apicall.length)
    return <h1 className="text-3xl">Wallet Not Connected</h1>;
  return (
    <>
      {apicall.map((nft, i) => {
        var owner = nft.wallet.toLowerCase();
        console.log(nft)
        if (owner.indexOf(nft.holder) !== -1) {
          async function stakeit() {
            vaultcontract.methods.stake([nft.tokenId]).send({ from: account });
          }
          return (
            <ContentCard
              img={nftpng + nft.tokenId + ".png"}
              tokenId={nft.tokenId}
              key={i}
              handleClick={stakeit}
              status={false}
            />
          );
        }
      })}
      {nftstk.map((nft, i) => {
        async function unstakeit() {
          vaultcontract.methods.unstake([nft.tokenId]).send({ from: account });
        }
        return (
          <ContentCard
            img={nftpng + nft.tokenId + ".png"}
            tokenId={nft.tokenId}
            key={i}
            handleClick={unstakeit}
            status={true}

          />
        );
      })}
    </>
  );
}
