import React from "react";
import { Link } from "react-router-dom";

import Container from "components/container";

import discord from "assets/discord-icon.svg";
import twitter from "assets/twitter-icon.svg";
import style from "./footer.module.scss";

const Footer = () => {
  return (
    <nav className={style.navbar}>
      <Container>
        <div className={style.navDiv}>
          <div className={style.titleDiv}>
            <span>NFT DAO</span>
            <span className={style.salogn}>Amsterdam Books</span>
          </div>
          <div className={style.menuWrapper}>
            {footerLink.map(({ title, link }) => (
              <li key={title}>
                <Link to={link}>{title}</Link>
              </li>
            ))}
          </div>
        </div>
        <div className={style.navDiv}>
          <div className={style.subtitle}>
            © Copyright by Amsterdam Books 2022. All rights reserved
          </div>
          <div className={style.submenu}>
            {navLinks.map(({ title, link, icon }) => (
              <li key={title}>
                <a href={link} target={"_blank"}>
                  <img src={icon} alt={"icon"} width={17} height={17} />
                </a>
              </li>
            ))}
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default Footer;

const navLinks = [
  {
    title: "Twitter",
    link: "https://twitter.com/amsterdam_books",
    icon: twitter,
  },
  {
    title: "Discord",
    link: "https://discord.gg/WfBJrpwSyT",
    icon: discord,
  },
];

const footerLink = [
];
