import React, { Component } from "react";
import Web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import WalletLink from "walletlink";
import { createAlchemyWeb3 } from "@alch/alchemy-web3";
import WalletConnectProvider from "@walletconnect/web3-provider";

import Footer from "components/footer";
import Button from "components/button";
import Card from "components/card";
import Container from "components/container";

import ABI from "./ABI.json";
import VAULTABI from "./VAULTABI.json";
import TOKENABI from "./TOKENABI.json";
import {
  NFTCONTRACT,
  STAKINGCONTRACT,
  polygonscanapi,
  moralisapi,
} from "./config";

import logo from "assets/logo.png";
import style from "./app.module.scss";
import NFT from "nft";

var account = null;
var contract = null;
var vaultcontract = null;
var web3 = null;

const Web3Alc = createAlchemyWeb3(
  "https://polygon-mainnet.g.alchemy.com/v2/eXdsc7gelDMFDXjE8frz6x8THX9jff7w"
);

const moralisapikey =
  "S4usFtOqBj40uqx3qIa6y9zA7dH0iRuv3US66vyBftkS5C9KKw7D5hPbdV1UsxHa";
const polygonscanapikey = "DBQX5JUSAVUZRK8CC4IN2UZF9N2HA63P4U";

const providerOptions = {
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnectProvider,

    options: {
      infuraId: "3cf2d8833a2143b795b7796087fff369",
    },
  },
  walletlink: {
    package: WalletLink,
    options: {
      appName: "Net2Dev NFT Minter",
      infuraId: "3cf2d8833a2143b795b7796087fff369",
      rpc: "",
      chainId: 4,
      appLogoUrl: null,
      darkMode: true,
    },
  },
};

const web3Modal = new Web3Modal({
  network: "rinkeby",
  theme: "dark",
  cacheProvider: true,
  providerOptions,
});

class App extends Component {
  constructor() {
    super();
    this.state = {
      balance: [],
      rawearn: [],
    };
  }

  handleModal() {
    this.setState({ show: !this.state.show });
  }

  handleNFT(nftamount) {
    this.setState({ outvalue: nftamount.target.value });
  }

  async componentDidMount() {
    await axios
      .get(
        polygonscanapi +
        `?module=stats&action=tokensupply&contractaddress=${NFTCONTRACT}&apikey=${polygonscanapikey}`
      )
      .then((outputa) => {
        this.setState({
          balance: outputa.data,
        });
        console.log(outputa.data);
      });
    let config = { "X-API-Key": moralisapikey, accept: "application/json" };
    await axios
      .get(
        moralisapi + `/nft/${NFTCONTRACT}/owners?chain=polygon&format=decimal`,
        { headers: config }
      )
      .then((outputb) => {
        const { result } = outputb.data;
        this.setState({
          nftdata: result,
        });
        console.log(outputb.data);
      });
  }

  render() {
    const { balance } = this.state;
    const { outvalue } = this.state;
    const statusTabs = [
      {
        title: "Authorize Your Wallet",
        fun: enable,
      },
      {
        title: "Claim ABCs Token",
        fun: claimit,
      },
      {
        title: "Earned ABCs Token",
        fun: rewardinfo,
      },
      {
        title: "Unstake All NFTs",
        fun: unstakeall,
      },
      {
        title: "Refresh Dashboard",
        fun: verify,
      },
    ];
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };

    const expectedBlockTime = 10000;

    async function connectwallet() {
      var provider = await web3Modal.connect();
      web3 = new Web3(provider);
      await provider.send("eth_requestAccounts");
      var accounts = await web3.eth.getAccounts();
      account = accounts[0];
      // document.getElementById("wallet-address").textContent = account;
      contract = new web3.eth.Contract(ABI, NFTCONTRACT);
      vaultcontract = new web3.eth.Contract(VAULTABI, STAKINGCONTRACT);
      var getstakednfts = await vaultcontract.methods
        .tokensOfOwner(account)
        .call();
      document.getElementById("yournfts").textContent = getstakednfts;
      var getbalance = Number(
        await vaultcontract.methods.balanceOf(account).call()
      );
      document.getElementById("stakedbalance").textContent = getbalance;
      document.getElementById("btnConnect").textContent = "Connected";
      const arraynft = Array.from(getstakednfts.map(Number));
      const tokenid = arraynft.filter(Number);
      var rwdArray = [];
      tokenid.forEach(async (id) => {
        var rawearn = await vaultcontract.methods
          .earningInfo(account, [id])
          .call();
        var array = Array.from(rawearn.map(Number));
        console.log(array);
        array.forEach(async (item) => {
          var earned = String(item).split(",")[0];
          var earnedrwd = Web3.utils.fromWei(earned);
          var rewardx = Number(earnedrwd).toFixed(2);
          var numrwd = Number(rewardx);
          console.log(numrwd);
          rwdArray.push(numrwd);
        });
      });
      function delay() {
        return new Promise((resolve) => setTimeout(resolve, 300));
      }
      async function delayedLog(item) {
        await delay();
        var sum = item.reduce((a, b) => a + b, 0);
        var formatsum = Number(sum).toFixed(2);
        // document.getElementById("earned").textContent = formatsum;
      }
      async function processArray(rwdArray) {
        for (const item of rwdArray) {
          await delayedLog(item);
        }
      }
      return processArray([rwdArray]);
    }

    async function verify() {
      var getstakednfts = await vaultcontract.methods
        .tokensOfOwner(account)
        .call();
      // document.getElementById("yournfts").textContent = getstakednfts;
      var getbalance = Number(
        await vaultcontract.methods.balanceOf(account).call()
      );
      // document.getElementById("stakedbalance").textContent = getbalance;
    }

    async function enable() {
      contract?.methods
        .setApprovalForAll(STAKINGCONTRACT, true)
        .send({ from: account });
    }
    async function rewardinfo() {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      var rwdArray = [];
      tokenid.forEach(async (id) => {
        var rawearn = await vaultcontract.methods
          .earningInfo(account, [id])
          .call();
        var array = Array.from(rawearn.map(Number));
        array.forEach(async (item) => {
          var earned = String(item).split(",")[0];
          var earnedrwd = Web3.utils.fromWei(earned);
          var rewardx = Number(earnedrwd).toFixed(2);
          var numrwd = Number(rewardx);
          rwdArray.push(numrwd);
        });
      });
      function delay() {
        return new Promise((resolve) => setTimeout(resolve, 300));
      }
      async function delayedLog(item) {
        await delay();
        var sum = item.reduce((a, b) => a + b, 0);
        var formatsum = Number(sum).toFixed(2);
        document.getElementById("earned").textContent = formatsum;
      }
      async function processArray(rwdArray) {
        for (const item of rwdArray) {
          await delayedLog(item);
        }
      }
      return processArray([rwdArray]);
    }
    async function claimit() {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      await Web3Alc.eth.getMaxPriorityFeePerGas().then((tip) => {
        Web3Alc.eth.getBlock("pending").then((block) => {
          var baseFee = Number(block.baseFeePerGas);
          var maxPriority = Number(tip);
          var maxFee = maxPriority + baseFee;
          tokenid.forEach(async (id) => {
            await vaultcontract.methods.claim([id]).send({
              from: account,
              maxFeePerGas: maxFee,
              maxPriorityFeePerGas: maxPriority,
            });
          });
        });
      });
    }
    async function unstakeall() {
      var rawnfts = await vaultcontract.methods.tokensOfOwner(account).call();
      const arraynft = Array.from(rawnfts.map(Number));
      const tokenid = arraynft.filter(Number);
      await Web3Alc.eth.getMaxPriorityFeePerGas().then((tip) => {
        Web3Alc.eth.getBlock("pending").then((block) => {
          var baseFee = Number(block.baseFeePerGas);
          var maxPriority = Number(tip);
          var maxFee = maxPriority + baseFee;
          tokenid.forEach(async (id) => {
            await vaultcontract.methods.unstake([id]).send({
              from: account,
              maxFeePerGas: maxFee,
              maxPriorityFeePerGas: maxPriority,
            });
          });
        });
      });
    }
    async function mintnative() {
      var _mintAmount = Number(outvalue);
      var mintRate = Number(await contract.methods.cost().call());
      var totalAmount = mintRate * _mintAmount;
      await Web3Alc.eth.getMaxPriorityFeePerGas().then((tip) => {
        Web3Alc.eth.getBlock("pending").then((block) => {
          var baseFee = Number(block.baseFeePerGas);
          var maxPriority = Number(tip);
          var maxFee = baseFee + maxPriority;
          contract.methods.mint(account, _mintAmount).send({
            from: account,
            value: String(totalAmount),
            maxFeePerGas: maxFee,
            maxPriorityFeePerGas: maxPriority,
          });
        });
      });
    }

    async function mint0() {
      var _pid = "0";
      var erc20address = await contract.methods.getCryptotoken(_pid).call();
      var currency = new web3.eth.Contract(TOKENABI, erc20address);
      var mintRate = await contract.methods.getNFTCost(_pid).call();
      var _mintAmount = Number(outvalue);
      var totalAmount = mintRate * _mintAmount;
      await Web3Alc.eth.getMaxPriorityFeePerGas().then((tip) => {
        Web3Alc.eth.getBlock("pending").then((block) => {
          var baseFee = Number(block.baseFeePerGas);
          var maxPriority = Number(tip);
          var maxFee = maxPriority + baseFee;
          currency.methods
            .approve(NFTCONTRACT, String(totalAmount))
            .send({
              from: account,
            })
            .then(
              currency.methods.transfer(NFTCONTRACT, String(totalAmount)).send(
                {
                  from: account,
                  maxFeePerGas: maxFee,
                  maxPriorityFeePerGas: maxPriority,
                },
                async function (error, transactionHash) {
                  console.log("Transfer Submitted, Hash: ", transactionHash);
                  let transactionReceipt = null;
                  while (transactionReceipt == null) {
                    transactionReceipt = await web3.eth.getTransactionReceipt(
                      transactionHash
                    );
                    await sleep(expectedBlockTime);
                  }
                  window.console = {
                    log: function (str) {
                      var out = document.createElement("div");
                      out.appendChild(document.createTextNode(str));
                      document.getElementById("txout").appendChild(out);
                    },
                  };
                  console.log("Transfer Complete", transactionReceipt);
                  contract.methods.mintpid(account, _mintAmount, _pid).send({
                    from: account,
                    maxFeePerGas: maxFee,
                    maxPriorityFeePerGas: maxPriority,
                  });
                }
              )
            );
        });
      });
    }
    const refreshPage = () => {
      window.location.reload();
    };

    return (
      <div className={style.layoutWrapper}>
        <nav className={style.navbar}>
          <Container>
            <div className={style.navDiv}>
              <div className={style.leftWrapper}>
                <div className={style.titleDiv}>
                  <img src={logo} className={style.logo} alt={"logo"} />
                </div>
                <div className={style.menuWrapper}>
                  {navLinks.map(({ title, id }) => (
                    <li key={title} id={id}>
                      {title}
                    </li>
                  ))}
                </div>
              </div>
              <div className={style.rightWrapper}>
                <Button
                  id={"btnConnect"}
                  title={"Connect your wallet"}
                  className={style.btnNav}
                  handleClick={connectwallet}
                />
              </div>
            </div>
          </Container>
        </nav>
        <main>
          <Container className={style.mainWrapper}>
            <div className={style.headerWrapper}>
              <h1 className={style.label}>BvsS NFTs Staking Dapp</h1>
              <ul className={style.listWrapper}>
                {tabLinks.map(({ title, id }, index) => (
                  <li key={index}>
                    {title} : <span id={id}></span>
                  </li>
                ))}
              </ul>
            </div>
            <div className={style.mintWrapper}>
              <Card className={style.cardWrapper}>
                <div className={style.statusWrapper}>
                  {statusTabs.map(({ title, fun }, index) => (
                    <div className={style.infoWrapper} key={index}>
                      <Button
                        title={title}
                        handleClick={fun}
                        className={style.infoData}
                      />
                    </div>
                  ))}
                </div>
                <div className={style.nftContent}>
                  <div className={style.nftcardWrapper}>
                    <NFT />
                  </div>
                </div>
              </Card>
            </div>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
export default App;

const navLinks = [
  {
    title: "Stake your BvsS NFTs to earn Amsterdam Books Coins,   and participate in the Amsterdam Books DAO.",
    link: "/graph",
  },
];

const tabLinks = [
  {
    title: "Total Staked NFTs",
    id: "stakedbalance",
  },
  {
    title: "Earned ABCs",
    id: "earned",
  },
  {
    title: "Staked NFTs #",
    id: "yournfts",
  },

];


